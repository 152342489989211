<template>
  <BasicModal :title="detail?.id ? '修改规则' : '新增规则'" width="600px" :visible="visible" :confirmLoading="confirmLoading"
    @ok="ok" @cancel="cancel">
    <div class="project-edit-content">
      <BasicForm class="project-edit-form" ref="formRef" :labelCol="{ span: 5 }" :formItemsMap="formItemsMap"
        v-model:formData="formData"></BasicForm>
    </div>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { apiDictListByCode } from "@/api/common.js";
import { apiConfig } from "@/api/centerMonitor/viewConfig.js";
import { apiRuleManage } from "@/api/IoT/ruleManage.js";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

onMounted(() => {
  initFormItems();
  initFormData();
});

const formItemsMap = reactive({
  name: {
    label: "规则名称",
    prop: "name",
    type: "input",
    required: true,
    requiredMessage: "请输入",
    span: 20,
    maxlength: 100
  },
  bizProjectId: {
    label: "所属项目",
    prop: "bizProjectId",
    type: "select",
    options: [],
    fieldNames: {
      label: "name",
      value: "bizProjectId",
    },
    required: true,
    requiredMessage: "请选择",
    span: 20,
  },
  ruleType: {
    label: "规则类型",
    prop: "ruleType",
    type: "select",
    options: [],
    required: true,
    requiredMessage: "请选择",
    span: 20,
  },
  ruleDesc: {
    label: "规则描述",
    prop: "ruleDesc",
    type: "textarea",
    requiredMessage: "请输入",
    span: 20,
  },
});
const initFormItems = async () => {
  apiDictListByCode("RULE_TYPE").then((res) => {
    formItemsMap.ruleType.options = res.result;
  });
  apiConfig.getProject().then((res) => {
    formItemsMap.bizProjectId.options = res.result || [];
  });
  formItemsMap.bizProjectId.disabled = !!props.detail;
  formItemsMap.ruleType.disabled = !!props.detail;

};
const formData = reactive({
  name: "",
  bizProjectId: undefined,
  ruleType: "",
  ruleDesc: "",
});
const initFormData = async () => {
  if (!props.detail) return;
  for (const key in formData) {
    formData[key] = props.detail[key];
  }
};


const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const params = {
      id: props.detail?.id,
      ...formData,
    };
    confirmLoading.value = true;
    await apiRuleManage.save(params);
    message.success(`${props.detail?.id ? '修改成功' : '新增成功'}`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>

<template>

  <div class="common-wrapper">
    <div class="spin" v-if="loading">
      <a-spin size="large" />
    </div>
    <header class="common-wrapper-header">
      <div>
        <div class="title">
          <span>{{ data.name }}</span>
          <form-outlined @click="showModal(null)" style="padding-left: 8px; color: #666666; cursor: pointer" />
        </div>
        <div class="content">
          <span>{{ data.bizProjectId }}</span>
          <a-divider type="vertical" style="height: 12px; background-color: #ebebeb" />
          <span>{{ data.ruleTypeDesc }}</span>
          <a-divider type="vertical" style="height: 12px; background-color: #ebebeb" />
          <span>{{ data.ruleDesc }}</span>
        </div>
      </div>
      <div v-if="data.ruleStatus == '01'">
        <a-button @click="changeStatus">停用</a-button>
      </div>
      <div v-else>
        <a-button type="primary" style="margin-right: 8px" @click="handleSave">保存</a-button>
        <a-button @click="changeStatus">启用</a-button>
      </div>
    </header>
    <a-divider style="height: 1px; background-color: #ebebeb" />
    <div style="padding-left: 10px">
      <div class="node">
        <div class="title">触发节点</div>
        <div class="content">
          <div>
            <a-select v-model:value="triggerVO.triggerType" :options="options.triggerType" placeholder="请选择触发方式"
              disabled>
            </a-select>
            <a-select v-model:value="triggerVO.targetBizProdId" placeholder="请选择产品" @change="changeTriggerProduct"
              :disabled="ruleStop">
              <a-select-option v-for="product in options.targetBizProdId" :key="product.id" :value="product.bizId">{{
                product.name }}</a-select-option>
            </a-select>
            <a-select v-model:value="triggerVO.messageType" :options="options.messageType" placeholder="请选择报文类型"
              :disabled="ruleStop">
            </a-select>
          </div>
          <a-select v-model:value="triggerVO.targetBizDeviceIds" mode="multiple" style="width: 100%; margin-top: 12px"
            :disabled="ruleStop" placeholder="请选择设备">
            <a-select-option v-for="device in options.targetBizDeviceIds" :key="device.id"
              :value="device.bizDeviceId">{{ device.name }}</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="node">
        <div class="title">条件节点</div>
        <div v-for="(item, idx) in conditionVOList" :key="idx">
          <div class="content">
            <div v-if="item.conditionType == '01'">
              <a-select v-model:value="item.conditionType" :options="options.conditionType" placeholder="请选择条件类型"
                :disabled="ruleStop" @change="changeConditionType(item)">
              </a-select>
              <a-time-picker v-model:value="item.judgeTimeStart" value-format="HH:mm" format="HH:mm"
                :disabled="ruleStop" placeholder="请选择开始时间" />
              <a-time-picker v-model:value="item.judgeTimeEnd" value-format="HH:mm" format="HH:mm" :disabled="ruleStop"
                placeholder="请选择结束时间" />
              <a-select v-model:value="item.repeatType" :options="options.repeatType" placeholder="请选择重复类型"
                :disabled="ruleStop" @change="changeRepeatType(item)" allowClear>
              </a-select>
              <div>
                <a-select v-model:value="item.repeatTime" style="margin-right: 12px;width: 100%;" mode="multiple"
                  :disabled="ruleStop" placeholder="请选择重复时间">
                  <a-select-option v-for="(item, idx) in options.repeatTime" :key="idx" :value="idx + 1 + ''">{{ item
                    }}</a-select-option>
                </a-select>
              </div>
            </div>
            <div v-else>
              <a-select v-model:value="item.conditionType" :options="options.conditionType" placeholder="请选择条件类型"
                :disabled="ruleStop" @change="changeConditionType(item)">
              </a-select>
              <a-select v-model:value="item.corBizProdId" placeholder="请选择产品" @change="changeConditionProduct(item)"
                :disabled="ruleStop">
                <a-select-option v-for="product in options.conditionTargetBizProdId" :key="product.id"
                  :value="product.bizId">{{ product.name }}</a-select-option>
              </a-select>
              <a-select v-model:value="item.corBizDeviceId" placeholder="请选择设备" :disabled="ruleStop">
                <a-select-option v-for="(device, idx) in item.deviceList" :key="idx" :value="device.bizDeviceId">{{
                  device.name }}</a-select-option>
              </a-select>
              <a-select v-model:value="item.attrCode" @change="changeConditionAttr(item)" placeholder="请选择属性"
                :disabled="ruleStop">
                <a-select-option v-for="(attr, idx) in item.attrsList" :key="idx" :value="attr.identifier">{{
                  attr.functionName }}</a-select-option>
              </a-select>
              <template v-if="item.attr">
                <a-select v-model:value="item.comparator" :options="item.comparatorOptions" :disabled="ruleStop"
                  style="width: 56px; margin-right: 12px">
                </a-select>
                <a-input v-if="item.attr.dataType == '01'" v-model:value="item.compareVal" placeholder="请输入比较值"
                  :disabled="ruleStop" />
                <a-input-number v-else-if="item.attr.dataType == '02' || item.attr.dataType == '03'
                " v-model:value="item.compareVal" placeholder="请输入比较值" :disabled="ruleStop" />
                <a-select v-else v-model:value="item.compareVal" placeholder="请选择比较值" :disabled="ruleStop">
                  <a-select-option v-for="attr in item.attr.valueDescription" :key="attr.key" :value="attr.key">{{
                    attr.value }}</a-select-option>
                </a-select>
              </template>
            </div>
            <div class="delete-btn" @click="deleteCondition(idx)">-</div>
          </div>
        </div>
        <div>
          <a-button type="link" @click="addCondition"> + 增加执行条件</a-button>
        </div>
      </div>
      <div class="node">
        <div class="title">执行节点</div>
        <div class="content">
          <div>
            <span>执行动作：</span>
            <a-select v-model:value="actionVO.actionType" :options="actionOptions.actionType" placeholder="请选择执行动作"
              disabled>
            </a-select>
            <span>告警码：</span>
            <a-input v-model:value="actionVO.alarmCode" placeholder="请输入告警码" :disabled="ruleStop" style="width: 146px">
              <template #prefix>
                <span>RU_</span>
              </template>
            </a-input>
          </div>
          <div style="margin-left: 70px">
            <div>
              <span>触发：</span>
              <a-select v-model:value="actionVO.alarmTriggerLevel" :options="actionOptions.alarmLevel"
                :disabled="ruleStop" placeholder="请选择触发等级">
              </a-select>
              <a-select v-model:value="actionVO.alarmTriggerConfirmType" :options="actionOptions.alarmConfirmType"
                :disabled="ruleStop" placeholder="请选择确认方式">
              </a-select>
              <a-input v-model:value="actionVO.alarmTriggerDesc" placeholder="请输入触发内容" style="width: 480px"
                :disabled="ruleStop" />
            </div>
            <div>
              <span>复归：</span>
              <a-select v-model:value="actionVO.alarmRelapseLevel" :options="actionOptions.alarmLevel"
                :disabled="ruleStop" placeholder="请选择复归等级">
              </a-select>
              <a-select v-model:value="actionVO.alarmRelapseConfirmType" :options="actionOptions.alarmConfirmType"
                :disabled="ruleStop" placeholder="请选择确认方式">
              </a-select>
              <a-input v-model:value="actionVO.alarmRelapseDesc" placeholder="请输入复归内容" style="width: 480px"
                :disabled="ruleStop" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <RuleEditModal v-if="modalVisible" v-model:visible="modalVisible" :detail="data" @success="editSuccess">
  </RuleEditModal>
</template>

<script setup>
import { apiRuleManage } from "@/api/IoT/ruleManage.js";
import { useRoute } from "vue-router";
import { reactive, ref, onMounted, computed, watch } from "vue";
import RuleEditModal from "./components/RuleEditModal";
import { apiDictListByCode } from "@/api/common.js";
import { apiGateWay } from "@/api/gateway.js";
import { apiProjectDevice as apiProjectDevices } from "@/api/IoT/productManage.js";
import { apiProductDetailDeviceAttribute } from "@/api/IoT/productCenter.js";
import { cloneDeep } from "lodash";
import { message } from "ant-design-vue";
const route = useRoute();

const data = ref({});
const weeks = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
const month = [
  "1号",
  "2号",
  "3号",
  "4号",
  "5号",
  "6号",
  "7号",
  "8号",
  "9号",
  "10号",
  "11号",
  "12号",
  "13号",
  "14号",
  "15号",
  "16号",
  "17号",
  "18号",
  "19号",
  "20号",
  "21号",
  "22号",
  "23号",
  "24号",
  "25号",
  "26号",
  "27号",
  "28号",
];
const options = reactive({
  triggerType: [],
  targetBizProdId: [],
  messageType: [],
  targetBizDeviceIds: [],
  conditionType: [],
  repeatType: [],
  repeatTime: [],
  conditionTargetBizProdId: [],
  comparator: [],
});
const loading = ref(false)

const initDetail = () => {
  apiRuleManage.detail(route.params.id).then(({ result }) => {
    data.value = result;
    if (result.triggerVO) {
      triggerVO.value = result.triggerVO;
      triggerVO.value.targetBizDeviceIds = result.triggerVO.targetBizDeviceIds ?
        result.triggerVO.targetBizDeviceIds.split(",") : [];
      if (result.triggerVO.targetProdId) {
        getTriggerDevices(result.triggerVO.targetProdId);
      }
      if (result.conditionVOList.length) {
        conditionVOList.value = result.conditionVOList;
        conditionVOList.value.forEach((item) => {
          if (item.conditionType == "01") {
            // 时间条件
            options.repeatTime = item.repeatType == "01" ? weeks : month;
            item.repeatTime = item.repeatTime ? item.repeatTime.split(",") : [];
          } else {
            // 设备条件
            // 获取设备和属性下拉
            getProductDevices(item, true);
            getProductAttrs(item, true);
          }
        });
      }
      let alarmCode = result.actionVO.alarmCode.split('RU_')[1]
      actionVO.value = { ...result.actionVO, alarmCode };
    }
  })
};
onMounted(() => {
  initDetail();
  initOptions();
});

// 切换规则状态
const changeStatus = () => {
  let m = {
    id: data.value.id,
    status: data.value.ruleStatus == "01" ? "02" : "01",
  };
  loading.value = true
  apiRuleManage.changeStatus(m).then(() => {
    initDetail();
  }).finally(() => {
    loading.value = false
  });
};

const ruleStop = computed(() => {
  return data.value.ruleStatus == "01"
})

const initOptions = () => {
  apiDictListByCode("RULE_TRIGGER_TYPE").then((res) => {
    options.triggerType = res.result;
  });

  apiDictListByCode("TARGET_MESSAGE_TYPE").then((res) => {
    options.messageType = res.result;
  });

  // 获取产品列表
  apiGateWay.getProducts().then(({ result }) => {
    options.targetBizProdId = result;
    options.conditionTargetBizProdId = [{ id: 0, bizId: '0', name: "当前产品" }, ...result];
  });

  apiDictListByCode("RULE_CONDITION_TYPE").then((res) => {
    options.conditionType = res.result;
  });
  apiDictListByCode("REPEAT_TYPE").then((res) => {
    options.repeatType = res.result;
  });
  apiDictListByCode("COMPARATOR_TYPE").then((res) => {
    options.comparator = res.result;
  });
  apiDictListByCode("RULE_ACTION_TYPE").then((res) => {
    actionOptions.actionType = res.result;
  });
  apiDictListByCode("ALARM_LEVEL").then((res) => {
    actionOptions.alarmLevel = res.result;
  });
  apiDictListByCode("ALARM_CONFIRM_TYPE").then((res) => {
    actionOptions.alarmConfirmType = res.result;
  });
};

const resetInitValue = () => {
  // 重置 
  triggerVO.value = {
    triggerType: "01",
    targetBizProdId: undefined,
    targetProdId: undefined,
    messageType: undefined,
    targetBizDeviceIds: [],
  }
  conditionVOList.value = [{ conditionType: "02" }]
  actionVO.value = {
    actionType: "01",
    alarmCode: undefined,
    alarmTriggerLevel: undefined,
    alarmTriggerConfirmType: undefined,
    alarmTriggerDesc: undefined,
    alarmRelapseLevel: undefined,
    alarmRelapseConfirmType: undefined,
    alarmRelapseDesc: undefined,
  }
}
watch(() => route.fullPath, (val) => {
  if (val.includes('IoTManage/ruleDetail')) {
    resetInitValue()
    initOptions()
    initDetail()
  }
})


// 触发节点
const triggerVO = ref({
  triggerType: "01",
  targetBizProdId: undefined,
  targetProdId: undefined,
  messageType: undefined,
  targetBizDeviceIds: [],
});

const changeTriggerProduct = (bizProductId) => {
  let item = options.targetBizProdId.find(e => e.bizId === bizProductId)
  getTriggerDevices(item.id);
  triggerVO.value.targetProdId = item.id;
  triggerVO.value.targetBizDeviceIds = [];

  // 检查条件节点是否有当前产品，如果有当前产品，切换产品后，重置产品后面的字段
  conditionVOList.value.forEach((item) => {
    if (item.corBizProdId == "0") {
      getProductDevices(item);
      getProductAttrs(item);

      if (item.corBizProdId == "0" && item.corBizDeviceId != '0') {
        item.corBizDeviceId = undefined;
      }
      item.attrCode = undefined;
      item.comparator = undefined;
      item.compareVal = undefined;
    }
  });
};
const getTriggerDevices = (productId) => {
  if (productId) {
    const params = {
      productId,
      pageNo: 1,
      pageSize: 999,
      bizProjectId: data.value.bizProjectId
    };
    apiProjectDevices.page(params).then(({ result }) => {
      options.targetBizDeviceIds = result.records;
    });
  }
};

const modalVisible = ref(false);
const showModal = () => {
  modalVisible.value = true;
};
const editSuccess = () => {
  initDetail()
};

// 条件节点
const conditionVOList = ref([{ conditionType: "02" }]);

// 只能有一个时间条件
const hasTimeCondition = computed(() => {
  return (
    conditionVOList.value.findIndex((item) => item.conditionType == "01") !== -1
  );
});
watch(
  () => hasTimeCondition.value,
  (val) => {
    options.conditionType[0].disabled = val;
  }
);
const addCondition = () => {
  if (!ruleStop.value) {
    conditionVOList.value.push({});
  }
};
const deleteCondition = (idx) => {
  if (!ruleStop.value) {
    conditionVOList.value.splice(idx, 1);
  }
};
// 每次切换条件类型，需要清空后面的选项
const changeConditionType = (item) => {
  if (item.conditionType == '01') {
    item.judgeTimeStart = undefined;
    item.judgeTimeEnd = undefined;
    item.repeatType = undefined;
    item.repeatTime = [];
  } else {
    item.corBizProdId = undefined;
    item.corBizDeviceId = undefined;
    item.attrCode = undefined;
    item.comparator = undefined;
    item.compareVal = undefined;
  }

};
const changeRepeatType = (item) => {
  options.repeatTime = item.repeatType == "01" ? weeks : month;
  item.repeatTime = [];
};

// 改变产品
const changeConditionProduct = (item) => {
  let conditionItem = options.conditionTargetBizProdId.find(e => e.bizId === item.corBizProdId)
  item.corProdId = conditionItem.id;
  getProductDevices(item);
  getProductAttrs(item);
  // 重置设备值和属性值
  item.corBizDeviceId = undefined;
  item.attrCode = undefined;
  item.comparator = undefined;
  item.compareVal = undefined;
};

// 获取产品的设备列表
const getProductDevices = (item) => {
  const params = {
    productId:
      item.corBizProdId == "0"
        ? triggerVO.value.targetProdId
        : item.corProdId,
    pageNo: 1,
    pageSize: 999,
    bizProjectId: data.value.bizProjectId
  };
  apiProjectDevices.page(params).then(({ result }) => {
    if (item.corBizProdId == "0") {
      item.deviceList = [
        { bizDeviceId: "0", name: "当前设备" },
        ...result.records,
      ]
    } else {
      item.deviceList = result.records
    }

  });
};

// 获取产品的属性列表
const getProductAttrs = (item, init) => {
  const params = {
    productId:
      item.corBizProdId == "0"
        ? triggerVO.value.targetProdId
        : item.corProdId,
    pageNo: 1,
    pageSize: 100,
  };
  apiProductDetailDeviceAttribute.list(params).then(({ result }) => {
    item.attrsList = result.records || [];
    if (result.records && result.records.length) {
      // 获取属性的值类型
      changeConditionAttr(item, init);
    }
  });
};

// 获取属性的值类型
const changeConditionAttr = (item, init) => {
  let attr = item.attrsList.find((e) => e.identifier === item.attrCode);
  item.attr = attr;
  item.comparatorOptions = cloneDeep(options.comparator);
  if (attr) {
    item.comparatorOptions.forEach((e) => {
      // 如果dataType不是数字或者浮点数，那么除了=和≠这两个，其它符号不可以选
      e.disabled =
        attr.dataType !== "02" &&
        attr.dataType !== "03" &&
        e.value !== "01" &&
        e.value !== "02";
    });
  }
  if (!init) {
    item.comparator = undefined;
    item.compareVal = undefined;
  }
};

// 执行节点
const actionVO = ref({
  actionType: "01",
  alarmCode: undefined,
  alarmTriggerLevel: undefined,
  alarmTriggerConfirmType: undefined,
  alarmTriggerDesc: undefined,
  alarmRelapseLevel: undefined,
  alarmRelapseConfirmType: undefined,
  alarmRelapseDesc: undefined,
});
const actionOptions = reactive({
  actionType: [],
  alarmLevel: [],
  alarmConfirmType: [],
});

const checkPass = () => {
  if (!triggerVO.value.targetBizProdId) {
    message.warning('请选择触发节点的产品')
    return false
  }
  if (!triggerVO.value.messageType) {
    message.warning('请选择触发节点的报文类型')
    return false
  }
  if (!triggerVO.value.targetBizDeviceIds.length) {
    message.warning('请选择触发节点的设备')
    return false
  }

  let idx = conditionVOList.value.findIndex(e => {
    if (!e.conditionType) {
      message.warning('请选择条件节点的条件类型')
      return true
    } else if (e.conditionType == "01") {
      // 时间条件
      if (!e.judgeTimeStart) {
        message.warning('请选择条件节点的开始时间')
        return true
      }
      if (!e.judgeTimeEnd) {
        message.warning('请选择条件节点的结束时间')
        return true
      }
      let timeStart = e.judgeTimeStart.split(':')
      let timeEnd = e.judgeTimeEnd.split(':')
      if ((timeStart[0] > timeEnd[0]) || (timeStart[0] == timeEnd[0]) && (timeStart[1] > timeEnd[1])) {
        message.warning('开始时间不能大于结束时间')
        return true
      }
      if (!e.repeatType) {
        message.warning('请选择条件节点的重复类型')
        return true
      }
      if (!e.repeatTime || !e.repeatTime.length) {
        message.warning('请选择条件节点的重复时间')
        return true
      }
      return false
    } else {
      // 设备条件
      if (!e.corBizProdId) {
        message.warning('请选择条件节点的产品')
        return true
      }
      if (!e.corBizDeviceId) {
        message.warning('请选择条件节点的设备')
        return true
      }
      if (!e.attrCode) {
        message.warning('请选择条件节点的属性')
        return true
      }
      if (!e.comparator) {
        message.warning('请选择条件节点的比较符')
        return true
      }
      if (!e.compareVal) {
        message.warning('请输入条件节点的比较值')
        return true
      }
      return false
    }
  })
  if (idx !== -1) {
    return false
  }

  if (!actionVO.value.actionType) {
    message.warning('请选择执行节点的执行动作')
    return false
  }
  if (!actionVO.value.alarmCode) {
    message.warning('请输入执行节点的告警码')
    return false
  }
  if (!actionVO.value.alarmTriggerLevel) {
    message.warning('请选择执行节点的触发等级')
    return false
  }
  if (!actionVO.value.alarmTriggerConfirmType) {
    message.warning('请选择执行节点的确认方式')
    return false
  }
  if (!actionVO.value.alarmTriggerDesc) {
    message.warning('请输入执行节点的触发内容')
    return false
  }
  if (!actionVO.value.alarmRelapseLevel) {
    message.warning('请选择执行节点的复归等级')
    return false
  }
  if (!actionVO.value.alarmRelapseConfirmType) {
    message.warning('请选择执行节点的确认方式')
    return false
  }
  if (!actionVO.value.alarmRelapseDesc) {
    message.warning('请输入执行节点的复归内容')
    return false
  }

  return true
}
const handleSave = () => {
  if (!checkPass()) {
    return
  }
  let conditionAddDTOList = cloneDeep(conditionVOList.value);
  let item = conditionAddDTOList.find((e) => e.conditionType == "01");
  if (item) {
    item.repeatTime = item.repeatTime.join(",");
  }

  let m = {
    id: route.params.id,
    triggerAddDTO: {
      ...triggerVO.value,
      targetBizDeviceIds: triggerVO.value.targetBizDeviceIds.join(","),
    },
    conditionAddDTOList: conditionAddDTOList,
    actionAddDTO: { ...actionVO.value, alarmCode: 'RU_' + actionVO.value.alarmCode },
  };
  loading.value = true
  apiRuleManage.saveDetail(m).then(() => {
    message.success("保存成功");
  }).finally(() => {
    loading.value = false
  });
};
</script>
<style lang="less" scoped>
.common-wrapper {
  position: relative;
}

.common-wrapper-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 10px;

  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: #38404c;
  }

  .content {
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }

  .ant-divider-vertical {
    margin: 0 12px;
  }
}

.ant-divider-horizontal {
  margin: 16px 0 0 0;
}

.node {
  position: relative;
  padding-left: 22px;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 10px;
    height: 10px;
    border: 2px solid #0256ff;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 30px;
    left: 4px;
    width: 1px;
    height: calc(100% - 10px);
    background-color: #ebebeb;
  }

  .title {
    padding-top: 8px;
    line-height: 38px;
  }

  .content {
    position: relative;
    margin-bottom: 12px;
    padding: 8px 20px 20px;
    background-color: #f6f6f6;
    border-radius: 4px;
  }

  .delete-btn {
    position: absolute;
    right: 9px;
    top: 9px;
    width: 16px;
    height: 16px;
    background: #acaebc;
    border-radius: 50%;
    text-align: center;
    line-height: 14px;
    color: #fff;
    cursor: pointer;
  }
}

.node:last-child {
  &::after {
    height: 0;
  }
}

.ant-select,
.ant-input,
.ant-picker,
.ant-input-number {
  margin-top: 12px;
  margin-right: 12px;
  width: 146px;
}

.spin {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.ant-spin {
  position: absolute;
  left: 50%;
  top: 50%;
}
</style>

// 网关
import http from "../utils/http";

export const apiGateWay = {
  list(params) {
    return http.request({
      url: "/cemp-bms/gateway/page-list",
      method: "get",
      params,
    });
  },
  add(data) {
    return http.request({
      url: "/cemp-bms/gateway",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-bms/gateway",
      method: "put",
      data,
    });
  },
  delete(id) {
    return http.request({
      url: "/cemp-bms/gateway",
      method: "delete",
      params: { bizId: id },
    });
  },
  detail(id) {
    return http.request({
      url: `/cemp-bms/gateway/${id}`,
      method: "get",
    });
  },
  getProducts() {
    return http.request({
      url: `/cemp-bms/gateway/products`,
      method: "get",
    });
  },
  getProject() {
    return http.request({
      url: `/cemp-bms/project/current-user/list`,
      method: "get",
    });
  },
  getService(){
    return http.request({
      url: `/cemp-bms/gateway/server-list`,
      method: "get",
    });
  },
  getServeDetail(id){
    return http.request({
      url: `/cemp-bms/gateway-server/info/${id}`,
      method: "get",
    });
  },
  //启动网关
  startGateway(bizId) {
    return http.request({
      url: `/cemp-bms/gateway/start`,
      method: "put",
      params:{bizId}
    });
  },
  //关闭网关
  stopGateway(bizId) {
    return http.request({
      url: `/cemp-bms/gateway/stop`,
      method: "put",
      params:{bizId}
    });
  },


  //网关下行js
  getGatewayJSDown(bizId) {
    return http.request({
      url: `/cemp-bms/gateway/${bizId}/js/down`,
      method: "get",
    });
  },
  //保存网关下行js
  saveGatewayJSDown(bizId,data) {
    return http.request({
      url: `/cemp-bms/gateway/${bizId}/js/down`,
      method: "post",
      data
    });
  },
  //网关下行js参考
  getGatewayJSDownFormat(bizId) {
    return http.request({
      url: `/cemp-bms/gateway/${bizId}/js/down/format`,
      method: "get",
    });
  },
  //模拟运行网关下行js
  runGatewayJSDown(bizId,data) {
    return http.request({
      url: `/cemp-bms/gateway/${bizId}/js/down/simulate`,
      method: "post",
      data
    });
  },


  //网关上行js
  getGatewayJSUp(bizId) {
    return http.request({
      url: `/cemp-bms/gateway/${bizId}/js/up`,
      method: "get",
    });
  },
  //保存网关上行js
  saveGatewayJSUp(bizId,data) {
    return http.request({
      url: `/cemp-bms/gateway/${bizId}/js/up`,
      method: "post",
      data
    });
  },
  //网关上行js参考
  getGatewayJSUpFormat(bizId) {
    return http.request({
      url: `/cemp-bms/gateway/${bizId}/js/up/format`,
      method: "get",
    });
  },
  //模拟运行网关上行js
  runGatewayJSUp(bizId,data) {
    return http.request({
      url: `/cemp-bms/gateway/${bizId}/js/up/simulate`,
      method: "post",
      data
    });
  },
  //启动前校验JS初始化
  checkGatewayJSInit(bizId) {
    return http.request({
      url: `/cemp-bms/gateway/check-js/${bizId}`,
      method: "get",
    });
  },
  //----网关消息监听---

  //总览
  monitorView(bizId){
    return http.request({
      url: `/cemp-monitor/gateway-monitor/msg-summary/${bizId}`,
      method: "get",
    });
  },
  //按日查询设备消息量列表
  messageList(bizId,params){
    return http.request({
      url: `/cemp-monitor/gateway-monitor/msg-by-month/device/${bizId}`,
      method: "get",
      params
    });
  },
  //按日查询上下行
  messageDay(bizId, params){
    return http.request({
      url: `/cemp-monitor/gateway-monitor/msg-by-day/${bizId}`,
      method: "get",
      params
    });
  },
  //按月查询上下行
  messageMonth(bizId,params){
    return http.request({
      url: `/cemp-monitor/gateway-monitor/msg-by-month/${bizId}`,
      method: "get",
      params
    });
  }
};

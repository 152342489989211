import http from "@/utils/http";

// 产品管理
export const apiProduct = {
  categoryTree() {
    return http.request({
      url: "/cemp-bms/product/manage/category/tree",
      method: "get",
    });
  },
  page(params) {
    return http.request({
      url: "/cemp-bms/product/manage/page-list",
      method: "get",
      params,
    });
  },
  delete(productId) {
    return http.request({
      url: `/cemp-bms/product/manage/${productId}`,
      method: "delete",
    });
  },
  deleteRef(productId) {
    return http.request({
      url: `/cemp-bms/product/manage/ref/${productId}`,
      method: "delete",
    });
  },
  add(data) {
    return http.request({
      url: "/cemp-bms/product/manage",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-bms/product/manage",
      method: "put",
      data,
    });
  },
  detail(productId) {
    return http.request({
      url: `/cemp-bms/product/manage/${productId}`,
      method: "get",
    });
  },
  allCategoryTree(params) {
    return http.request({
      url: "/cemp-bms/category/category-catalogue/tree",
      method: "get",
      params,
    });
  },
};

export const apiAlarmCode = {
  list(params) {
    return http.request({
      url: "/cemp-bms/product/alarm/page-query",
      method: "get",
      params
    });
  },
  add(data) {
    return http.request({
      url: "/cemp-bms/product/alarm",
      method: "post",
      data
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-bms/product/alarm",
      method: "put",
      data
    });
  },
  delete(id) {
    return http.request({
      url: `/cemp-bms/product/alarm/${id}`,
      method: "delete",
    });
  },
  validationUniq(data) {
    return http.request({
      url: `/cemp-bms/product/alarm/check-code-unique`,
      method: "post",
      data
    });
  },
  download() {
    return http.request({
      url: `/cemp-bms/product/alarm/export/template`,
      method: "get",
      responseType: "blob",
    });
  },
  upload(data, productId) {
    return http.request({
      url: `/cemp-bms/product/alarm/import/${productId}`,
      method: "post",
      data,
    });
  },
}
// 产品详情 - 设备列表
export const apiProjectDevice = {
  page(data) {
    return http.request({
      url: "/cemp-bms/device-monitor/list",
      method: "post",
      data,
    });
  },
  delete(id) {
    return http.request({
      url: `/cemp-bms/device-monitor/delete`,
      method: "post",
      params: {
        id,
      },
    });
  },
  add(data) {
    return http.request({
      url: "/cemp-bms/device-monitor/save",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-bms/device-monitor/edit",
      method: "post",
      data,
    });
  },
  parameterList(productId) {
    return http.request({
      url: "/cemp-bms/product/device-parameter/listByProduct",
      method: "get",
      params: {
        productId,
      },
    });
  },
  upload(data, productId) {
    return http.request({
      url: `/cemp-bms/device-monitor/import/${productId}`,
      method: "post",
      data,
    });
  },
  download(productId) {
    return http.request({
      url: `/cemp-bms/device-monitor/export/${productId}`,
      method: "get",
      responseType: "blob",
    });
  },
};

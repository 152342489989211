import http from "@/utils/http";
// 规则管理
export const apiRuleManage = {
  page(params) {
    return http.request({
      url: "/cemp-engine/rule/page",
      method: "get",
      params,
    });
  },
  delete(id) {
    return http.request({
      url: `/cemp-engine/rule/remove`,
      method: "post",
      params: {
        id,
      },
    });
  },
  save(data) {
    return http.request({
      url: "/cemp-engine/rule/save",
      method: "post",
      data,
    });
  },
  detail(id) {
    return http.request({
      url: "/cemp-engine/rule/detail",
      method: "get",
      params: { id },
    });
  },
  changeStatus(params) {
    return http.request({
      url: "/cemp-engine/rule/status/change",
      method: "post",
      params,
    });
  },
  saveDetail(data) {
    return http.request({
      url: "/cemp-engine/rule/detail/save",
      method: "post",
      data,
    });
  },
};

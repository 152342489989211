import http from '../../utils/http'

export const apiFunctionCodeUnique = (params) => {
    return http.request({
        url: '/cemp-bms/feature/identifier/unique',
        method: 'get',
        params
    })
}
export const apiProductFunctionCodeUnique = (params) => {
    return http.request({
        url: '/cemp-bms/product/feature/identifier/unique',
        method: 'get',
        params
    })
}
// 功能管理-产品参数
export const apiFunctionProductParams = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/function/product/parameter/page',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/function/product/parameter',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/function/product/parameter',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/function/product/parameter/${id}`,
            method: 'delete',
        })
    }
}
// 功能管理-设备属性
export const apiFunctionDeviceAttribute = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/function/device/attribute/page',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/function/device/attribute',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/function/device/attribute',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/function/device/attribute/${id}`,
            method: 'delete',
        })
    }
}
// 功能管理-设备参数
export const apiFunctionDeviceParams = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/function/device/parameter/page',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/function/device/parameter',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/function/device/parameter',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/function/device/parameter/${id}`,
            method: 'delete',
        })
    }
}
// 功能管理-设备事件
export const apiFunctionDeviceEvent = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/function/device/event/page',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/function/device/event',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/function/device/event',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/function/device/event/${id}`,
            method: 'delete',
        })
    }
}
// 功能管理-设备服务
export const apiFunctionDeviceService = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/function/device/service/page',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/function/device/service',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/function/device/service',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/function/device/service/${id}`,
            method: 'delete',
        })
    }
}


// 品类管理-目录
export const apiCategoryManageDir = {
    // 列表
    list(containCategory) {
        return http.request({
            url: '/cemp-bms/category/category-catalogue/tree',
            method: 'get',
            params: { containCategory }
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/category/catalogue',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/category/catalogue',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/category/catalogue/${id}`,
            method: 'delete',
        })
    },
    // 目录名唯一性验证
    dirNameUnique(params) {
        return http.request({
            url: `/cemp-bms/category/catalogue/validation`,
            method: 'get',
            params
        })
    },
    // 目录名唯一性验证
    categoryNameUnique(params) {
        return http.request({
            url: `/cemp-bms/category/category/validation`,
            method: 'get',
            params
        })
    },
    // 目录名唯一性验证
    categoryCodeUnique(params) {
        return http.request({
            url: `/cemp-bms/category/code/unique`,
            method: 'get',
            params
        })
    },

}
// 品类管理-品类详情
export const apiCategoryDetail = {
    // 详情
    detail(id) {
        return http.request({
            url: `/cemp-bms/category/category/${id}`,
            method: 'get',
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/category/category',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/category/category',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/category/category/${id}`,
            method: 'delete',
        })
    },
}

// 品类管理-产品参数
export const apiCategoryProductParams = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/category/product-parameter',
            method: 'get',
            params
        })
    },
    // 新增列表
    addList(params) {
        return http.request({
            url: '/cemp-bms/category/candidate/product-parameter',
            method: 'get',
            params
        })
    },
    // 修改
    edit(data) {
        return http.request({
            url: '/cemp-bms/category/product-parameter',
            method: 'put',
            data
        })
    },
}
// 品类管理-设备参数
export const apiCategoryDeviceParams = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/category/device-parameter',
            method: 'get',
            params
        })
    },
    // 新增列表
    addList(params) {
        return http.request({
            url: '/cemp-bms/category/candidate/device-parameter',
            method: 'get',
            params
        })
    },
    // 修改
    edit(data) {
        return http.request({
            url: '/cemp-bms/category/device-parameter',
            method: 'put',
            data
        })
    },
}
// 品类管理-设备属性
export const apiCategoryDeviceAttribute = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/category/device-attribute',
            method: 'get',
            params
        })
    },
    // 新增列表
    addList(params) {
        return http.request({
            url: '/cemp-bms/category/candidate/device-attribute',
            method: 'get',
            params

        })
    },
    // 修改
    edit(data) {
        return http.request({
            url: '/cemp-bms/category/device-attribute',
            method: 'put',
            data
        })
    },
}
// 品类管理-设备事件
export const apiCategoryDeviceEvent = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/category/device-event',
            method: 'get',
            params
        })
    },
    // 新增列表
    addList(params) {
        return http.request({
            url: '/cemp-bms/category/candidate/device-event',
            method: 'get',
            params

        })
    },
    // 修改
    edit(data) {
        return http.request({
            url: '/cemp-bms/category/device-event',
            method: 'put',
            data
        })
    },
}
// 品类管理-设备服务
export const apiCategoryDeviceService = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/category/device-service',
            method: 'get',
            params
        })
    },
    // 新增列表
    addList(params) {
        return http.request({
            url: '/cemp-bms/category/candidate/device-service',
            method: 'get',
            params

        })
    },
    // 修改
    edit(data) {
        return http.request({
            url: '/cemp-bms/category/device-service',
            method: 'put',
            data
        })
    },
}
// 品类管理-品类中功能相关操作
export const apiCategoryOpFunction = {
    // 添加
    add(data) {
        return http.request({
            url: '/cemp-bms/category/function/addition',
            method: 'post',
            data
        })
    },
    // 添加
    delete(data) {
        return http.request({
            url: '/cemp-bms/category/function/omission',
            method: 'put',
            data
        })
    },
}

// 产品库-设备服务
export const apiProductLibrary = {
    // 品类树
    tree() {
        return http.request({
            url: '/cemp-bms/product/repo/category/tree',
            method: 'get',
        })
    },
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/product/repo/page-list',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/product/repo',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/product/repo',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/product/repo/${id}`,
            method: 'delete',
        })
    },
    // 修改产品状态
    changeStatus(data) {
        return http.request({
            url: `/cemp-bms/product/repo/status`,
            method: 'put',
            data
        })
    },
    // 引用产品
    deliver(productId) {
        return http.request({
            url: `/cemp-bms/product/repo/ref/product`,
            method: 'post',
            data: { productId }
        })
    },

}

// 产品详情
export const apiProductDetail = {
    // 从产品库进去
    detail(productId) {
        return http.request({
            url: `/cemp-bms/product/repo/${productId}`,
            method: 'get',
        })
    },
    // 从产品管理进去
    detail2(productId) {
        return http.request({
            url: `/cemp-bms/product/manage/${productId}`,
            method: 'get',
        })
    }

}
// 产品详情-产品参数
export const apiProductDetailProductParams = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/product/product-parameter/page-list',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/product/product-parameter/custom',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/product/product-parameter',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/product/product-parameter/${id}`,
            method: 'delete',
        })
    },
    // 删除
    setValue(data) {
        return http.request({
            url: `/cemp-bms/product/product-parameter/value`,
            method: 'put',
            data
        })
    },
}
// 产品详情-设备属性
export const apiProductDetailDeviceAttribute = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/product/device-attribute/page-list',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/product/device-attribute/custom',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/product/device-attribute',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/product/device-attribute/${id}`,
            method: 'delete',
        })
    }
}
// 产品详情-设备参数
export const apiProductDetailDeviceParams = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/product/device-parameter/page-list',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/product/device-parameter/custom',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/product/device-parameter',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/product/device-parameter/${id}`,
            method: 'delete',
        })
    }
}
// 产品详情-设备事件
export const apiProductDetailDeviceEvent = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/product/device-event/page-list',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/product/device-event/custom',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/product/device-event',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/product/device-event/${id}`,
            method: 'delete',
        })
    }
}
// 产品详情-设备服务
export const apiProductDetailDeviceService = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-bms/product/device-service/page-list',
            method: 'get',
            params
        })
    },
    // 新增
    add(data) {
        return http.request({
            url: '/cemp-bms/product/device-service/custom',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-bms/product/device-service',
            method: 'put',
            data
        })
    },
    // 删除
    delete(id) {
        return http.request({
            url: `/cemp-bms/product/device-service/${id}`,
            method: 'delete',
        })
    }
}

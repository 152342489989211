import http from "@/utils/http";

/*
 * 视图配置-----分页查询视图列表
 * @param {*} params
 * @returns
 */
export const apiConfig = {
  page(params) {
    return http.request({
      url: "/cemp-monitor/view/page",
      method: "get",
      params,
    });
  },
  add(data) {
    return http.request({
      url: `/cemp-monitor/view/save`,
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: `/cemp-monitor/view/update`,
      method: "put",
      data,
    });
  },
  copy(data) {
    return http.request({
      url: `/cemp-monitor/view/duplicate`,
      method: "post",
      data,
    });
  },
  detail(viewId) {
    return http.request({
      url: `/cemp-monitor/view/info/${viewId}`,
      method: "get",
    });
  },
  delete(viewId) {
    return http.request({
      url: `/cemp-monitor/view/detele/${viewId}`,
      method: "delete",
    });
  },
  status(viewId) {
    return http.request({
      url: `/cemp-monitor/view/change-status/${viewId}`,
      method: "put",
    });
  },
  getProject() {
    return http.request({
      url: `/cemp-bms/project/current-user/list`,
      method: "get",
    });
  },
};
